import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Layout from 'desktop/components/Layout';
import { SignMenu } from 'common/components/SignMenu';

import { useTop100Context } from 'common/contexts/top100Context';

const selectData = createSelector(
  [(state: IAppState) => state.runtime.reloadKey],
  (reloadKey) => ({
    reloadKey,
  }),
);

function SignCompatibility() {
  const { reloadKey } = useSelector(selectData);
  const { top100Prefix } = useTop100Context();

  return (
    <Layout
      withSchema
      centerColumn={
        <SignMenu
          key={`sign-compatibility-${reloadKey}`}
          type="compatibility"
          top100Prefix={top100Prefix}
        />
      }
    />
  );
}

export default SignCompatibility;
