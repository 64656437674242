/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import { Typography } from 'common/components/Typography';
import { getTop100Markup } from 'common/utils/getTop100Markup';

import s from './index.css';

function Legal({ top100Prefix }: { top100Prefix: string }) {
  return (
    <div className={s.legal}>
      <Typography
        variant="smallMedium"
        className={s.legalLink}
        element={
          <a
            href="https://help.rambler.ru/legal/1142/"
            target="_blank"
            data-block="privacy"
            rel="noreferrer"
          />
        }
        {...getTop100Markup(false, top100Prefix, 'Политика конфиденциальности')}
      >
        Политика конфиденциальности
      </Typography>
      <Typography
        variant="smallMedium"
        className={s.legalLink}
        element={
          <a
            href="https://help.rambler.ru/legal/1430/"
            target="_blank"
            data-block="terms"
            rel="noreferrer"
          />
        }
        {...getTop100Markup(false, top100Prefix, 'Условия использования')}
      >
        Условия использования
      </Typography>
    </div>
  );
}

export default Legal;
