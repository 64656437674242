import { useRef, useState, useEffect } from 'react';
import _debounce from 'lodash.debounce';

interface UseScrollProps {
  scrollSticky: boolean;
  stickyPointY: number;
  dropdownOpened?: boolean;
  setDropdownOpened?: React.Dispatch<React.SetStateAction<boolean | string>>;
}

const DEBOUNCE_WAITING_TIME = 10;

// костыль для FF
const ZERO_PIXEL =
  typeof window !== 'undefined' &&
  window.navigator.userAgent.toLowerCase().indexOf('firefox') === -1
    ? 0
    : 2;

export const useScroll = ({
  scrollSticky,
  stickyPointY,
  dropdownOpened,
  setDropdownOpened,
}: UseScrollProps) => {
  /* Состояние открытого дропдауна */
  const isDropdown = useRef(dropdownOpened);

  /* Нода, которую отслеживаем для прилипания */
  const scrollableNode = useRef<HTMLDivElement>(null);

  /* Рефы состояний нужны, что бы не пересоздавать scroll при каждом их изменении */
  const isStickyRef = useRef(false);
  const isVisibleRef = useRef(false);

  /* Значения скролла при разном направлении */
  const fromUp = useRef(0);
  const fromDown = useRef(0);

  /* Текущее значение скролла */
  const prevPageYOffset = useRef(0);

  /* Состояния для прлипания */
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  /* Изменение состояния прилипания */
  const setSticky = (nextSticky: boolean) => {
    isStickyRef.current = nextSticky;
    setIsSticky(nextSticky);
  };

  /* Изменение состояния отображеннии при прилипании */
  const setVisible = (nextVisible: boolean) => {
    isVisibleRef.current = nextVisible;
    setIsVisible(nextVisible);
  };

  useEffect(() => {
    /* Скролл страницы */
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const scroll = _debounce(() => {
      // если нечего прилипать
      if (!scrollableNode.current) {
        setSticky(false);
        setVisible(false);

        return;
      }

      // размерности ноды
      const { top, height } = scrollableNode.current.getBoundingClientRect();
      const pageYOffset = -1 * top;

      // если нода еще не прилипла, но уже показалась во вьюпорте,
      // то отменяем действие
      if (!isVisibleRef.current && top + height > 0) {
        setSticky(false);

        return;
      }

      const scrolledSpacing = stickyPointY;

      if (pageYOffset > scrolledSpacing && !isStickyRef.current) {
        setSticky(true);

        if (isDropdown.current && setDropdownOpened) {
          setDropdownOpened(false);
        }
      } else if (isStickyRef.current) {
        if (pageYOffset <= ZERO_PIXEL) {
          fromUp.current = 0;
          fromDown.current = 0;
          setSticky(false);
          setVisible(false);
        } else if (pageYOffset > prevPageYOffset.current) {
          fromDown.current = 0;

          if (!fromUp.current) {
            fromUp.current = pageYOffset;
          }

          if (fromUp.current < pageYOffset - scrolledSpacing) {
            setVisible(false);
          }

          if (
            fromUp.current < pageYOffset - scrolledSpacing &&
            isDropdown.current &&
            setDropdownOpened
          ) {
            setDropdownOpened(false);
          }
        } else if (pageYOffset < prevPageYOffset.current) {
          fromUp.current = 0;

          if (!fromDown.current) {
            fromDown.current = pageYOffset;
          }

          if (fromDown.current > pageYOffset + scrolledSpacing) {
            setVisible(true);
          }
        }

        prevPageYOffset.current = pageYOffset;
      }
    }, DEBOUNCE_WAITING_TIME);

    if (scrollSticky) {
      window.addEventListener('scroll', scroll);
    }

    return () => {
      window.removeEventListener('scroll', scroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stickyPointY > 0) {
      isDropdown.current = dropdownOpened;
    }
  }, [stickyPointY, dropdownOpened]);

  return {
    scrollableNode,
    isSticky,
    isVisible,
  };
};
