import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { getAdForInnerBlocks } from 'config/constants/content';
import Layout from 'desktop/components/Layout';

import Card from 'common/components/Card';
import { CardHeader } from 'common/components/Card/Header';
import { HairLink } from 'common/components/Card/@Hair/Link';
import { Bubbles } from 'common/components/Card/Bubbles';
import { CalendarContext } from 'common/components/Card/CalendarContext';
import { Calendar } from 'common/components/Card/Calendar';
import { Tags } from 'common/components/Card/Tags';
import { Footer } from 'common/components/Card/Footer';

import rootStyles from 'common/components/Card/styles.css';

function HairDatePage() {
  const data = useSelector((state: IAppState) => state.pages.hair.date);
  const [calendarViewOpened, setCalendarViewOpened] = useState(true);

  const calendarContextMemo = useMemo(
    () => [calendarViewOpened, setCalendarViewOpened],
    [calendarViewOpened],
  );

  if (!data) return null;

  const { content } = data;
  const isMobile = false;

  const {
    id,
    title,
    link,
    type,
    is_h2_title: isH2Title,
    is_h3_title: isH3Title,
    isMainCard,
    isSubCard,
    bubbles,
    calendar,
    tags,
    likes,
    inner_blocks: innerBlocks,
  } = content;

  return (
    <Layout
      withoutMainCard
      centerColumn={
        <div className={rootStyles.root}>
          <CardHeader
            isMobile={isMobile}
            titleProps={{
              title,
              isMobile,
              link,
              type,
              isLink: false,
              isH2Title,
              isH3Title,
              isMainCard,
              isH1Title: true,
              isHanding: false,
              isSubCard,
            }}
            socialProps={{
              links: {},
              isMobile,
            }}
          />
          <HairLink />
          <Bubbles
            className={cn(
              rootStyles.bubbles,
              rootStyles.bubblesDesktop,
              rootStyles.bubblesMenu,
            )}
            bubbles={bubbles}
            isCardBubblesMoved
          />
          {calendar && (
            <CalendarContext.Provider value={calendarContextMemo}>
              <Calendar
                className={cn(rootStyles.calendar, rootStyles.calendarDesktop)}
                calendar={calendar}
                isMobile={isMobile}
              />
            </CalendarContext.Provider>
          )}
          {innerBlocks?.map((card: Partial<ICardProps>, cardIndex) => {
            const key = `${id}-${cardIndex}`;
            const isFirstCard = cardIndex === 0;
            const ad = getAdForInnerBlocks({
              isMobile: false,
              index: cardIndex,
              length: innerBlocks.length,
              ignoreEnd: true,
            });

            return (
              <Fragment key={key}>
                <Card
                  id={`${key}-card`}
                  type="widgets_with_inner_blocks_sub"
                  isMainCard={false}
                  isSubCard
                  isHairSubCardHighlight={isFirstCard}
                  title=""
                  {...card}
                />
                {ad}
              </Fragment>
            );
          })}
          <Tags
            wrapperClassName={cn(rootStyles.tags, rootStyles.tagsDesktop)}
            tags={tags}
            isMobile={isMobile}
          />
          <Footer
            className={cn(rootStyles.footer, rootStyles.footerDesktop)}
            likesClassName={cn(rootStyles.likes, rootStyles.likesDesktop)}
            title={title}
            link={link}
            likes={likes}
            isMobile={isMobile}
            key={likes ? likes.codename : ''}
          />
        </div>
      }
    />
  );
}

export default HairDatePage;
