import 'polyfills';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';

import * as SentryReact from '@sentry/react';
import 'utils/sleep-refresh';

import { YANDEX_METRICS } from 'config/constants/counters';

import { YandexEvent } from 'utils/counters/YandexEvent';
import { initSentry } from 'utils/sentry/client';

import App from 'desktop/App';
import configureStore from 'common/redux/configure';

import { webVitalsMetrics } from 'utils/metrics/webVitals';
import { clientMetricsBatch } from 'utils/metrics/clientMetricsBatch';
import { initServiceWorker } from 'service-worker/initServiceWorker';

declare const window: EnhancedWindow;

const container = document.getElementById('root');

const store = configureStore(window.__PRELOADED_STATE__);

window.ramblerIdHelper = window.ramblerIdHelper || [];
window.ramblerIdHelper.push(() => {
  window.ramblerIdHelper.addListener('register', () => {
    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: 'ID_registration',
      params: {
        auth_type: 'ID_authorized',
      },
    });
  });
});

const state = store.getState();

clientMetricsBatch.init({
  pageName: state.runtime.currentPage,
  pageSection: state.runtime.currentSection,
  sampleRate: state.runtime.config.metricsSampleRate,
});
webVitalsMetrics(state);
initServiceWorker(state.runtime);
initSentry(state.runtime);

// eslint-disable-next-line promise/catch-or-return
loadableReady().then(() => {
  const component = (
    <BrowserRouter>
      <Provider store={store}>
        <SentryReact.ErrorBoundary>
          <App />
        </SentryReact.ErrorBoundary>
      </Provider>
    </BrowserRouter>
  );

  if (module.hot && container) {
    const root = createRoot(container);

    root.render(component);
  } else if (container) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, sonarjs/no-unused-vars, sonarjs/no-dead-store
    const root = hydrateRoot(container, component);
  }
});

if (module.hot) {
  module.hot.accept();
}
