interface ArgsType {
  src: string;
  async?: boolean;
  defer?: boolean;
  onload?: (e: Event) => void;
}

/**
 * Создает новый тег script и вставляет его перед первым элементом тега head
 *
 * @param src - script.src
 * @param async - script.async
 * @param defer - script.defer
 * @param onload - script.onload
 */
export const insertScriptIntoHead = ({
  src,
  async = true,
  defer = false,
  onload,
}: ArgsType): void => {
  const { head } = document;
  const script = document.createElement('script');

  script.src = src;

  if (!defer) {
    script.async = async;
  }

  script.defer = defer;

  if (onload) {
    script.onload = onload;
  }

  head.insertBefore(script, head.firstChild);
};
