import { Metric, onLCP, onFID, onCLS, onFCP, onTTFB } from 'web-vitals';
import { initPerfume } from 'perfume.js';

import { sendMetrics } from './sendMetrics';

interface IMetrics extends Omit<Metric, 'name'> {
  name: Metric['name'] | 'TBT';
}

export const webVitalsMetrics = (state: IAppState) => {
  const sendQueue = new Set<IMetrics>();

  const addToSendQueue: (metric: IMetrics) => void = (metric) => {
    sendQueue.add(metric);
  };

  const flushSendQueue = () => {
    if (sendQueue.size <= 0) return;

    sendMetrics(
      JSON.stringify({
        pageName: state.runtime.currentPage,
        pageSection: state.runtime.currentSection,
        metrics: [...sendQueue],
      }),
      state,
    );

    sendQueue.clear();
  };

  onLCP(addToSendQueue);
  onFID(addToSendQueue);
  onCLS(addToSendQueue);
  onFCP(addToSendQueue);
  onTTFB(addToSendQueue);

  initPerfume({
    analyticsTracker: (options: any) => {
      const { attribution, metricName, data, rating, navigationType } = options;
      const newRating =
        rating === 'needsImprovement' ? 'needs-improvement' : rating;

      if (metricName === 'TBT') {
        addToSendQueue({
          name: 'TBT',
          value: data as number,
          rating: newRating as any,
          delta: data as number,
          id: '',
          entries: attribution?.eventEntry ? [attribution.eventEntry] : [],
          navigationType: navigationType as any,
        });
      }
    },
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'hidden') {
      flushSendQueue();
    }
  });

  // Safari хак (вместо visibilitychange)
  document.addEventListener('pagehide', flushSendQueue);
};
