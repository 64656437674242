import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

// eslint-disable-next-line import/no-named-as-default
import Helmet from 'react-helmet';

import { Route, Redirect } from 'react-router-dom';
import { AdProvider } from '@rambler-components/ad';

import Snackbar from '@rambler-components/snackbar';
import {
  TOP100_ID,
  TOP100_COMMON_ID,
  TOP100_RAMBLER_ID,
  YANDEX_METRICS,
  RCM_ID,
} from 'config/constants/counters';

import { getDesktopRoutes } from 'desktop/routes';

import { Head } from 'common/components/Head';
import { AdblockDetect } from 'common/components/AdblockDetect';
import { PageSwitch } from 'common/components/PageSwitch';
import { Notifications } from 'common/components/Notifications';

import { TopPart } from 'desktop/components/TopPart';
import { useSendRcmPageView } from 'common/hooks/useSendRcmPageView';
import { useAppTheme } from 'common/hooks/useAppTheme';
import { splitsToArray } from 'common/utils/splitsToArray';

import { top100Wrapper } from 'utils/counters/top100Wrapper';
import { YandexEvent } from 'utils/counters/YandexEvent';
import { sentryReactSend } from 'utils/sentry/client';

import { fetchHairData, fetchMoonData } from 'common/redux/header';

import 'sanitize.css';
import 'css/base.css';
import '@rambler-components/tooltip/styles.css';
import '@rambler-components/button/styles.css';
import '@rambler-components/calendar/styles.css';
import '@rambler-components/field-status/styles.css';
import '@rambler-components/geo/styles.css';
import '@rambler-components/input/styles.css';
import '@rambler-components/loader/styles.css';
import '@rambler-components/popup/styles.css';
import '@rambler-components/radio-button/styles.css';
import '@rambler-components/search/styles.css';
import '@rambler-components/select/styles.css';
import '@rambler-components/snackbar/styles.css';
import '@rambler-components/tabs/styles.css';
import '@rambler-components/textarea/styles.css';
import '@rambler-components/checkbox/styles.css';
import '@rambler-components/hint/styles.css';

import 'desktop/css/variables.css';
import 'desktop/css/root.css';
import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { getPWADisplayMode } from 'common/utils/get-pwa-display-mode';

const sendAdblockInfo = () => {
  new YandexEvent(YANDEX_METRICS.COMMON).send({
    type: 'params',
    data: {
      AdBlock: window.isAdblockEnabled ? 'yes' : 'no',
    },
  });
};

const selectPadAndDebug = createSelector(
  [
    (state: IAppState) => state.runtime.banners.padID,
    (state: IAppState) => state.runtime.isDebug,
    (state: IAppState) => state.runtime.currentParams.isFullScreen,
    (state: IAppState) => state.runtime.ruid,
    (state: IAppState) => state.runtime.horoSplit,
    (state: IAppState) => state.runtime.splits,
    (state: IAppState) => state.runtime.ramblerId,
  ],
  (
    padID,
    isDebug,
    isFullScreen,
    ruid,
    horoSplit,
    splits,
    ramblerId,
    // eslint-disable-next-line max-params
  ) => ({
    padID,
    isDebug,
    isFullScreen,
    ruid,
    horoSplit,
    splits,
    ramblerId,
  }),
);

function App() {
  const dispatch = useDispatch();

  const { padID, isDebug, isFullScreen, ruid, horoSplit, splits, ramblerId } =
    useSelector(selectPadAndDebug);

  useSendRcmPageView();
  useAppTheme();

  const routes = getDesktopRoutes();

  useEffect(() => {
    dispatch({
      type: SET_RUNTIME_VARIABLE,
      name: 'PWADisplayMode',
      value: getPWADisplayMode(),
    });
    top100Wrapper.init({
      projectIds: [TOP100_ID, TOP100_COMMON_ID, TOP100_RAMBLER_ID],
      pub_id: ruid,
      rambler_id: ramblerId,
      attributes_dataset: ['horo-new', 'cerber-topline', 'cerber'],
      splits: ['new', horoSplit, ...splitsToArray(splits)],
    });

    sendAdblockInfo();

    top100Wrapper.push(() => {
      if (typeof window.rcm === 'function') {
        window.rcm('setup', {
          rcmId: RCM_ID,
          xuid: ruid,
        });
      }
    });

    const eventComplete = 'reactDomComplete';

    try {
      document.dispatchEvent(
        new Event(eventComplete, {
          bubbles: false,
          cancelable: false,
        }),
      );
    } catch (error) {
      const ev = document.createEvent('HTMLEvents');

      // eslint-disable-next-line sonarjs/deprecation
      ev.initEvent(eventComplete, false, false);
      document.dispatchEvent(ev);
      sentryReactSend(error);
    }

    dispatch(fetchHairData('hair'));
    dispatch(fetchMoonData('moon'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdProvider
      padId={padID}
      publisherId={ruid}
      publisherScope=".rambler.ru"
      isDebug={__DEV__ || isDebug}
    >
      <Helmet bodyAttributes={{ class: 'ad_branding_site' }} />
      <Head />
      <AdblockDetect />
      {!isFullScreen && (
        <>
          <Notifications />
          <TopPart />
        </>
      )}
      <PageSwitch appRoutes={routes}>
        {routes.map((route) => {
          if (route.redirect) {
            return (
              <Redirect
                {...route}
                from={route.path}
                to={route.redirect}
                key={route.name}
              />
            );
          }

          return <Route {...route} key={route.name} />;
        })}
      </PageSwitch>
      <Snackbar />
    </AdProvider>
  );
}

export default App;
