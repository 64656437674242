const lettersMap = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'zh',
  з: 'z',
  и: 'i',
  й: 'y',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ь: '',
  ы: 'y',
  ъ: '',
  э: 'e',
  ю: 'yu',
  я: 'ya',
};

export const generateChpu = (text: string) => {
  const symbols = text.toLowerCase().split('');

  const chpu = symbols
    // подставляем значения из карты символов для каждой буквы, если значения нет оставляем как есть
    .map((item: keyof typeof lettersMap) => lettersMap[item] || item)
    // собираем в строку
    .join('')
    // меняем все кроме цифр и латинских букв на дефис
    .replace(/[^-0-9a-z]/g, '-')
    // если в строке несколько дефисов подряд, меняем на один
    .replace(/-+/g, '-')
    // удаляем дефис в начале и в конце
    .replace(/(^-)|(-$)/g, '');

  return chpu;
};
