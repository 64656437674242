import React from 'react';

import { date, moonPeriods, moonDays, months } from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const moonRoutes: IAppRoute[] = [
  {
    name: 'moon-main',
    path: '/moon/calendar',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData(
          'moon',
          'calendar',
          {
            url: 'main',
          },
          false,
          {
            top100: 'moon_main',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'moon-period',
    path: `/moon/calendar/:period(${moonPeriods}|${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'moon',
          'calendar',
          {
            url: period,
            moonPeriod: period,
          },
          false,
          {
            top100: `moon_period_${period}`,
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'moon-date',
    path: `/moon/calendar/:year(${date.year})-:month(${date.month})-:day(${date.day})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { year, month, day } = params;

      await dispatch(
        fetchPageData(
          'moon',
          'calendar',
          {
            url: `${year}-${month}-${day}`,
            moonDate: `${year}-${month}-${day}`,
          },
          false,
          {
            top100: 'moon_period_date',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'moon-day',
    path: `/moon/day/:day(${moonDays})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { day } = params;

      await dispatch(
        fetchPageData(
          'moon',
          'day',
          {
            url: day,
            moonDay: day,
          },
          false,
          {
            top100: 'moon_day',
          },
        ),
      );
    },
  },
  {
    name: 'moon-full-main',
    path: '/moon/full-moon',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('moon', 'full', undefined, false, {
          top100: 'full_moon_main',
        }),
      );
    },
  },
  {
    name: 'moon-full-month',
    path: `/moon/full-moon/:month(${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { month } = params;

      await dispatch(
        fetchPageData(
          'moon',
          'full',
          {
            url: month,
          },
          false,
          {
            top100: 'full_moon_month',
          },
        ),
      );
    },
  },
  {
    name: 'moon-new-main',
    path: '/moon/new-moon',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('moon', 'new', undefined, false, {
          top100: 'new_moon_main',
        }),
      );
    },
  },
  {
    name: 'moon-new-month',
    path: `/moon/new-moon/:month(${months})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { month } = params;

      await dispatch(
        fetchPageData(
          'moon',
          'new',
          {
            url: month,
          },
          false,
          {
            top100: 'new_moon_month',
          },
        ),
      );
    },
  },
];
