import loadable from '@loadable/component';
import 'core-js/features/array/includes';
import 'core-js/features/array/find-index';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/promise';

if (__BROWSER__ && window && !window.IntersectionObserver) {
  // @ts-expect-error: Could not find a declaration file for module 'intersection-observer'
  loadable(() => import('intersection-observer'));
}

if (__BROWSER__ && window && !window.URL) {
  // @ts-expect-error: Could not find a declaration file for module 'url-polyfill'
  loadable(() => import('url-polyfill'));
}
