import React, { Fragment, useMemo, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useLocation } from 'react-router';
import cn from 'classnames';
import loadable from '@loadable/component';

import { APP_VERSION } from 'config/constants';
import {
  TEXT_RANGE_BEFORE_TOGGLE,
  TEXT_COMPATIBILITY_FORM_SYMBOLS_COUNT,
} from 'config/constants/content';

import { updateCounters } from 'utils/counters/updater';
import { safeGet } from 'utils/safeGet';
import { selectPageData } from 'utils/selectPageData';

import { Ad } from 'common/components/Ad';
import { SignBubbles } from 'common/components/Card/SignBubbles';
import { RcmWidget } from 'common/components/RCMWidget';
import { TarotMenu } from 'common/components/Tarot/TarotMenu';
import { TarotMenuWithButton } from 'common/components/Tarot/TarotMenuWithButton';
import { HairScroll } from 'common/components/Card/@Hair/Scroll';
import { UpdateFeed } from 'common/components/Alerts/UpdateFeed';
import { ThemesAlert } from 'common/components/Alerts/ThemesAlert';
import { Article } from 'common/components/schemaOrg/Article';
import { FeedbackForm } from 'common/components/FeedbackForm';
import { PersonalAccount } from 'common/components/PersonalAccount';
import { RetrogradeMercuryAlert } from 'common/components/Alerts/RetrogradeMercury';
import { Typography } from 'common/components/Typography';
import SubscribePopupInLayout from 'common/components/NewsLetterSubscribe/SubscribePopup/SubscribePopupInLayout';
import { Menu } from 'desktop/components/Menu/';
import { Footer } from 'desktop/components/Footer';
import Legal from 'desktop/components/Legal';

import { Top100Context } from 'common/contexts/top100Context';
import { getCardWeight } from 'config/constants/cardsWeights';
import { SUBSCRIBE_POPUP_TYPE_BY_PAGE } from 'config/constants/news-letter';

import s from './styles.css';

const Card = loadable(() => import('common/components/Card'));

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.top100,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.runtime.isStickyTopline,
    (state: IAppState) => state.meta.seo_text,
    (state: IAppState) => state.runtime.path,
  ],
  (
    top100,
    currentPage,
    currentSection,
    isBranding,
    isStickyTopline,
    seoText,
    currentPath,
    // eslint-disable-next-line max-params
  ) => ({
    top100,
    currentPage,
    currentSection,
    isBranding,
    isStickyTopline,
    seoText,
    currentPath,
  }),
);

const orderedBanners = [
  'superfooter', // billboard
  'superfooter',
  'superfooter', // parallax
  'context',
  'context',
  'context',
];

interface LayoutPropsType {
  /**
   * Содержимое блока, расположенного над столбцами
   */
  topSection?: JSX.Element;
  /**
   * Содержмое блока контента.
   * Размещается после основной карточки и до дополнительных карточек
   */
  centerColumn?: JSX.Element;
  /**
   * Флаг главной страницы
   */
  isHomePage?: boolean;
  /**
   * Нужна ли разметка schema
   */
  withSchema?: boolean;
  /**
   * Нужна ли главная карточка
   */
  withoutMainCard?: boolean;
  /**
   * Отображаем ли меню карт Таро
   */
  withTarotMenu?: boolean;
  /**
   * Нужна ли форма с датой рождения
   */
  withoutPersonal?: boolean;
  /**
   * Нужны ли бабблы
   */
  withoutBubbles?: boolean;
  /**
   * Нужен ли попап подписки
   */
  withSubscribePopup?: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function Layout({
  topSection,
  centerColumn,
  isHomePage,
  withSchema,
  withoutMainCard,
  withTarotMenu,
  withoutBubbles,
  withoutPersonal,
  withSubscribePopup,
}: LayoutPropsType) {
  const {
    top100,
    currentPage,
    currentSection,
    isBranding,
    isStickyTopline,
    seoText,
  } = useSelector(selectData);
  const data = useSelector((state: IAppState) =>
    selectPageData(state, currentPage, currentSection),
  );

  const countersUpdateList = useRef<Record<string, boolean>>({});

  const location = useLocation<{ loading?: boolean }>();

  const mainTitle = safeGet(() => data.pageTitle);
  const mainCardData: ICardProps = safeGet(() => data.content);
  const otherCardsData: ICardProps[] = safeGet(() => data.cards);

  const longreadTopicList = safeGet(() => data.topics);
  const bubbles = safeGet(() => data.bubbles, []);

  const mainCardType = safeGet(() => mainCardData.type);

  const isMainCardLongreadDetail = mainCardType === 'longread_detail';
  const isHairCard = currentPage === 'hair';
  const isHairDate = currentPage === 'hair' && currentSection === 'date';
  /** При клиентском роутинге нужно ждать когда обновятся данные,
   * иначе какое-то время будут отображаться данные предыдущего роута
   */
  const cardDataLoading = Boolean(location.state?.loading);

  const superBullboardAfterMainCard = useMemo(() => {
    let contentLength = 0;

    if (mainCardData) {
      const pureText = (text: string) =>
        text
          .replace(/\s{2,}/g, '')
          // eslint-disable-next-line sonarjs/slow-regex
          .replace(/<\/?[^>]+(>|$)/g, '')
          .replace(/[\r|\n]/g, '');

      if (!isMainCardLongreadDetail) {
        if (mainCardData.text && !mainCardData.draftParsed) {
          mainCardData.text.forEach((text) => {
            if (text.type === 'toggle-text') {
              let subTextLength = 0;

              safeGet(() => text.text, []).forEach((subText: any) => {
                // eslint-disable-next-line sonarjs/no-nested-functions
                subTextLength += safeGet(() => subText.content, '').length;
              });

              contentLength +=
                subTextLength > TEXT_RANGE_BEFORE_TOGGLE
                  ? TEXT_RANGE_BEFORE_TOGGLE
                  : subTextLength;
            } else if (text.type === 'compatibility') {
              contentLength += TEXT_COMPATIBILITY_FORM_SYMBOLS_COUNT;
            } else {
              contentLength += safeGet(() => text.content, '').length;
            }
          });
        } else if (mainCardData.draftParsed) {
          contentLength += pureText(mainCardData.draftParsed).length;
        }
      } else if (isMainCardLongreadDetail && mainCardData.article) {
        let articleText = '';

        if (mainCardData.article.body && !mainCardData.article.draftParsed) {
          articleText = mainCardData.article.body;
        } else if (mainCardData.article.draftParsed) {
          articleText = mainCardData.article.draftParsed;
        }

        contentLength += pureText(articleText).length;
      }
    }

    const isWeight100 =
      typeof mainCardData === 'undefined'
        ? false
        : getCardWeight(mainCardData.type, false) === 100;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return !!(contentLength > 1600 || withTarotMenu || isWeight100);
  }, [mainCardData]); // eslint-disable-line react-hooks/exhaustive-deps

  const top100Prefix = `${top100}_${mainCardData ? 'content_page' : 'list_page'}`;
  const top100PrefixMemo = useMemo(() => ({ top100Prefix }), [top100Prefix]);
  const top100MainCardMemo = useMemo(
    () => ({ top100Prefix: `${top100Prefix}::content_card::page1_card1` }),
    [top100Prefix],
  );
  const top100CardMemo = useCallback(
    ({
      isStoriesCard,
      group,
      top100Page,
      cardNumber,
      type,
      storiesCardNumber,
    }) => {
      if (isStoriesCard) {
        return {
          top100Prefix: `${top100Prefix}::${group}::page${top100Page}_card${cardNumber}::${type}::${storiesCardNumber}`,
        };
      }

      return {
        top100Prefix: `${top100Prefix}::${group}::page${top100Page}_card${cardNumber}`,
      };
    },
    [top100Prefix],
  );

  let cardNumber = mainCardData ? 1 : 0;
  let otherCardsCount = 0;
  let storiesCardNumber = 0;

  const divProps = withSchema
    ? {
        itemScope: true,
        // eslint-disable-next-line sonarjs/no-clear-text-protocols
        itemType: 'http://schema.org/Article',
      }
    : {};

  let title = null;

  if (!isHomePage && (mainTitle || seoText)) {
    title = mainTitle ? (
      <Typography variant="defaultMedium" component="h1" className={s.title}>
        {mainTitle}
      </Typography>
    ) : (
      <Typography variant="defaultMedium" className={s.title}>
        {seoText}
      </Typography>
    );
  }

  let currentWeight =
    typeof mainCardType === 'undefined'
      ? 0
      : getCardWeight(mainCardType, false);
  let currentBanner = 0;
  let bannersIteration = 1;

  if (superBullboardAfterMainCard) {
    currentBanner = 0;
    currentWeight = 0;
    bannersIteration = 2;
  }

  return (
    <div
      {...divProps}
      className={cn(s.main, isBranding && s.mainBranding, 'ad_branding_main')}
    >
      {withTarotMenu && (
        <Top100Context.Provider value={top100PrefixMemo}>
          <TarotMenuWithButton />
        </Top100Context.Provider>
      )}
      <Menu
        top100Prefix={top100Prefix}
        wrapperClassName={cn(s.menu, isBranding && s.menuBranding)}
        subClassName={s.menuSub}
      />
      <div
        className={cn(s.topPart, 'top-part', isBranding && s.topPartBranding)}
      >
        <RetrogradeMercuryAlert
          className={s.retrogradeMercuryAlert}
          view="horizontal"
          isMobile={false}
        />
        {!withoutBubbles && (
          <Top100Context.Provider value={top100PrefixMemo}>
            <SignBubbles
              className={cn(s.topBubbles, isBranding && s.topBubblesBranding)}
              bubbles={bubbles}
            />
          </Top100Context.Provider>
        )}
        {!withoutPersonal && (
          <PersonalAccount
            className={cn(
              s.topPersonalAccount,
              bubbles && s.topPersonalAccountWithBubbles,
              (!bubbles || !bubbles.length || withoutBubbles) &&
                s.topPersonalAccountç,
              isBranding && s.topPersonalAccountBranding,
            )}
            isWithBubbles={Boolean(
              bubbles && bubbles.length && !withoutBubbles,
            )}
            top100Prefix={top100Prefix}
          />
        )}
      </div>
      {withSchema && <Article />}

      <div
        className={cn(
          s.root,
          isBranding && s.rootBranding,
          withoutBubbles && withoutPersonal && s.rootWithoutSpace,
        )}
      >
        {topSection ? (
          <div className={s.top}>
            <Top100Context.Provider value={top100PrefixMemo}>
              {topSection}
            </Top100Context.Provider>
          </div>
        ) : null}
        <div className={s.left}>
          <Top100Context.Provider value={top100PrefixMemo}>
            <RetrogradeMercuryAlert view="vertical" isMobile={false} />
            {!withoutBubbles && <SignBubbles bubbles={bubbles} />}
            {!withoutPersonal && (
              <PersonalAccount
                className={cn(
                  s.personalAccount,
                  bubbles?.length && s.personalAccountWithBubbles,
                )}
              />
            )}
            {withTarotMenu && (
              <TarotMenu
                isMobile={false}
                top100Prefix={`${top100Prefix}::tarot_navigation`}
                isCompact={false}
              />
            )}
          </Top100Context.Provider>
        </div>
        <div className={s.center}>
          <Top100Context.Provider value={top100PrefixMemo}>
            <UpdateFeed isHomePage={isHomePage} />
            <ThemesAlert />
          </Top100Context.Provider>
          {title}
          {!cardDataLoading && !withoutMainCard && mainCardData && (
            <Top100Context.Provider value={top100MainCardMemo}>
              <Card
                {...mainCardData}
                isMainCard
                isHairCard={isHairCard}
                isHairDate={isHairDate}
                isLazy={false}
                isNeedLongreadTopicList={false}
              />
            </Top100Context.Provider>
          )}
          {!cardDataLoading && mainCardData && centerColumn && (
            <Top100Context.Provider value={top100PrefixMemo}>
              {centerColumn}
            </Top100Context.Provider>
          )}
          {isHairCard && currentSection === 'main' && (
            <Top100Context.Provider value={top100PrefixMemo}>
              <HairScroll />
            </Top100Context.Provider>
          )}
          {superBullboardAfterMainCard && (
            <Ad
              name="superfooter"
              wrapperClassName={s.banner}
              bannerCount={bannersIteration}
              puid44={`context_item${bannersIteration}`}
              isLazy
              isExBillboard
              onRender={() => {
                if (!countersUpdateList.current[0]) {
                  countersUpdateList.current[0] = true;
                  updateCounters(APP_VERSION.DESKTOP);
                }
              }}
            />
          )}
          {!cardDataLoading &&
            otherCardsData.map((card, cardIndex) => {
              let needRenderBanner = false;

              currentWeight += getCardWeight(card.type, false);

              if (currentWeight >= 100) {
                needRenderBanner = true;
                currentWeight = 0;
                currentBanner += 1;
              }

              const isFirstPage = bannersIteration === 1;
              let neededAd = orderedBanners[currentBanner];

              if (!neededAd) {
                currentBanner = 0;
                bannersIteration += 1;
                neededAd = orderedBanners[currentBanner];
              }

              const isExBillboard = currentBanner === 0;

              const key = `${currentPage}-${currentSection}-${card.id}`;

              const top100Page = bannersIteration;

              const isLazy =
                isMainCardLongreadDetail ||
                !isFirstPage ||
                (isFirstPage && cardIndex >= 2);
              const isStoriesCard = card.type === 'stories';

              cardNumber += 1;

              if (card.type !== 'handing_title') {
                otherCardsCount += 1;
              }

              if (isStoriesCard) {
                storiesCardNumber += 1;
              }

              const isNeedLongreadTopicList =
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                isFirstPage && otherCardsCount === 3;
              const isH1Title =
                cardIndex === 0 && isHomePage && !mainCardData && isFirstPage;

              return (
                <Fragment key={key}>
                  <Top100Context.Provider
                    value={top100CardMemo({
                      isStoriesCard,
                      group: card.group,
                      top100Page,
                      cardNumber,
                      type: card.type,
                      storiesCardNumber,
                    })}
                  >
                    <Card
                      {...card}
                      isLazy={isLazy}
                      isH1Title={isH1Title}
                      title={isH1Title && mainTitle ? mainTitle : card.title}
                      isNeedLongreadTopicList={isNeedLongreadTopicList}
                      longreadTopicList={longreadTopicList}
                    />
                  </Top100Context.Provider>
                  {needRenderBanner && (
                    <Ad
                      name={neededAd}
                      wrapperClassName={s.banner}
                      bannerCount={isExBillboard ? bannersIteration : undefined}
                      puid44={
                        isExBillboard
                          ? `context_item${bannersIteration}`
                          : undefined
                      }
                      isLazy
                      isExBillboard={isExBillboard}
                      onRender={() => {
                        if (
                          isExBillboard &&
                          !countersUpdateList.current[cardIndex]
                        ) {
                          countersUpdateList.current[cardIndex] = true;
                          updateCounters(APP_VERSION.DESKTOP);
                        }
                      }}
                    />
                  )}
                </Fragment>
              );
            })}
          <Footer />
        </div>
        <div className={s.right}>
          <Ad name="Promovidzhet" />
          <Ad name="ban_300x600" isLazy />
          <Legal top100Prefix={top100Prefix} />
          <RcmWidget />
          <Ad
            name="ban_300x600_2"
            wrapperClassName={cn(s.sticky, isStickyTopline && s.stickyGap)}
          />
        </div>
      </div>
      <FeedbackForm />

      {withSubscribePopup && (
        <SubscribePopupInLayout
          type={SUBSCRIBE_POPUP_TYPE_BY_PAGE[currentPage]}
        />
      )}
    </div>
  );
}

Layout.defaultProps = {
  topSection: undefined,
  centerColumn: undefined,
  isHomePage: false,
  withSchema: false,
  withoutMainCard: false,
  withoutPersonal: false,
  withoutBubbles: false,
  withTarotMenu: false,
  withSubscribePopup: false,
};

export default Layout;
