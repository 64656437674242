interface CreateNodeType {
  type?: string;
  alt?: string;
  src: string;
  root?: any;
  callback?: any;
  id?: any;
}

/**
 * Создает ноду и вставляет ее перед элементом или первым скриптом на странице
 *
 * @param params - параметры для создания ноды
 * @param params.type - тег ноды
 * @param params.alt - атрибут для ноды
 * @param params.src - атрибут src у будущей ноды
 * @param params.root - нода, рядом с которой встанет скрипт
 * @param params.callback - колбек, срабатывающий после загрузки данных от ноды
 */
export const createNode = (params: CreateNodeType) => {
  let parent: Node & ParentNode;

  const elem: any = document.createElement(
    params.type ? params.type : 'script',
  );

  elem.src = params.src;

  if (params.id) {
    elem.id = params.id;
  }

  if (params.alt) {
    elem.alt = params.alt;
  }

  if (params.type === 'script') {
    if (params.root) {
      parent = document.querySelector(params.root);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      parent = document.querySelectorAll('script')[0].parentNode!;
    }

    elem.async = true;
  } else {
    parent = document.body;
    elem.style.position = 'absolute';
    elem.style.width = 0;
    elem.style.height = 0;
    elem.style.top = '-1px';
    elem.style.left = '-1px';
  }

  const append = () => {
    parent.insertBefore(elem, parent.firstChild);
  };

  if (params.callback) {
    const callback = () => {
      const state = elem.readyState;

      if (!params.callback.done && (!state || /loaded|complete/.test(state))) {
        params.callback.done = true;
        params.callback();
      }
    };

    elem.onreadystatechange = callback;
    elem.onload = callback;
  }

  if (window.opera === '[object Opera]') {
    elem.addEventListener('DOMContentLoaded', append, false);
  } else {
    append();
  }
};
