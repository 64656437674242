import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Принимает url и возвращает строку ссылки в зависимости от абсолютного или относительного урла.
 *
 * @param url URL, string
 * @param origin domain, string
 */

export const getLinkFromUrl = (url: string, origin: string) =>
  url.includes('http') ? url : `${origin}${url}`;

/**
 * Принимает url и возвращает компонент ссылки в зависимости от абсолютного или относительного урла.
 *
 * @param url URL, string
 */

const HOROSCOPES_DOMAIN = 'horoscopes.rambler.ru';

export const getLinkElemFromUrl = (url: string): JSX.Element => {
  if (url.includes('http')) {
    if (url.includes(HOROSCOPES_DOMAIN)) {
      const relativeLink = url.split('.ru')[1];

      return <Link to={relativeLink} />;
    }

    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        aria-label="Открыть ссылку в новой вкладке"
      />
    );
  }

  return <Link to={url} />;
};
