/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, {
  useState,
  useRef,
  useMemo,
  useEffect,
  useCallback,
  Fragment,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';
import _throttle from 'lodash.throttle';

import { Link } from 'react-router-dom';

import { YANDEX_METRICS } from 'config/constants/counters';
import { desktopSubMenu } from 'config/constants/desktopMenu';
import { getTopics } from 'common/utils/navigation';
import { ProjectLinkType } from 'config/constants/projectLinks';
import { YandexEvent } from 'utils/counters/YandexEvent';
import { getTop100Markup } from 'common/utils/getTop100Markup';
import { safeGet } from 'utils/safeGet';

import { Icon } from 'common/components/Icon';

import { useOnClickOutside } from 'common/hooks/useOnClickOutside';

import { useScroll } from './uses/use-scroll';
import s from './styles.css';

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.currentBurgerSection,
    (state: IAppState) => state.runtime.isBranding,
    (state: IAppState) => state.runtime.currentParams.zodiacSign,
    (state: IAppState) => state.runtime.currentParams.chineseSign,
    (state: IAppState) => state.runtime.currentParams.chineseCompatibilitySign,
  ],
  (
    currentBurgerSection,
    isBranding,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
    // eslint-disable-next-line max-params
  ) => ({
    currentBurgerSection,
    isBranding,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  }),
);

interface MenuPropsType {
  top100Prefix: string;
  className?: string;
  subClassName?: string;
  wrapperClassName?: string;
  withSubMenu?: boolean;
}

/**
 * Необходимо менять значения при изменении количества / порядка / длины слов пунктов меню:
 ** full - количество подпунктов на широком экране
 ** fullOpened - количество подпунктов на широком экране при третьем уровне меню
 ** short - количество подпунктов на узком экране
 ** shortOpened - количество подпунктов на узком экране при третьем уровне меню
 */
const SUBITEMS_COUNT: Record<
  number,
  {
    full: number;
    fullOpened: number;
    short: number;
    shortOpened: number;
  }
> = {
  // знаки зодиака
  1: {
    full: 7,
    fullOpened: 5,
    short: 5,
    shortOpened: 3,
  },
  // таро
  2: {
    full: 6,
    fullOpened: 6,
    short: 4,
    shortOpened: 4,
  },
  // онлайн гадания
  5: {
    full: 8,
    fullOpened: 8,
    short: 6,
    shortOpened: 6,
  },
  // статьи
  9: {
    full: 11,
    fullOpened: 11,
    short: 8,
    shortOpened: 8,
  },
  // имена
  11: {
    full: 10,
    fullOpened: 10,
    short: 7,
    shortOpened: 7,
  },
};
const DROPDOWN_ITEM_HEIGHT = 40;
const ITEMS_COUNT_DEFAULT = 11;
const SUBITEMS_COUNT_DEFAULT = 8;

function Menu({
  top100Prefix,
  className,
  subClassName,
  wrapperClassName,
  withSubMenu,
}: MenuPropsType) {
  const {
    currentBurgerSection,
    isBranding,
    zodiacSign,
    chineseSign,
    chineseCompatibilitySign,
  } = useSelector(selectData);

  const [isFirstLevelDropdownOpened, setIsFirstLevelDropdownOpened] =
    useState(false);
  const [isFirstLevelMoreActive, setIsFirstLevelMoreActive] = useState(false);
  const [firstLevelItemsCount, setFirstLevelItemsCount] =
    useState(ITEMS_COUNT_DEFAULT);
  const firstLevelMoreContainerNode = useRef<HTMLDivElement>(null);

  const [isSecondLevelDropdownOpened, setIsSecondLevelDropdownOpened] =
    useState(false);
  const [isSecondLevelMoreActive, setIsSecondLevelMoreActive] = useState(false);
  const [secondLevelItemsCount, setSecondLevelItemsCount] = useState(
    SUBITEMS_COUNT_DEFAULT,
  );
  const secondLevelContainerNode = useRef<HTMLDivElement>(null);

  const finalChineseSign = chineseSign || chineseCompatibilitySign;

  const isBottomMenuShown = isSecondLevelDropdownOpened ? 'hide' : 'show';

  const { scrollableNode, isSticky, isVisible } = useScroll({
    scrollSticky: true,
    stickyPointY: 80,
    dropdownOpened: isFirstLevelDropdownOpened,
    setDropdownOpened: setIsFirstLevelDropdownOpened,
  });

  const firstLevelItems: ProjectLinkType[] = useMemo(
    () =>
      getTopics({
        currentBurgerSection,
        isMobile: false,
        zodiacSign,
        chineseSign: finalChineseSign,
      }),
    [zodiacSign, finalChineseSign, currentBurgerSection],
  );

  const firstLevelVisibleItems = useMemo(
    () => firstLevelItems.slice(0, firstLevelItemsCount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBurgerSection, firstLevelItems, firstLevelItemsCount],
  );
  const firstLevelDropdownItems = useMemo(
    () => [...firstLevelItems.slice(firstLevelItemsCount)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBurgerSection, firstLevelItems, firstLevelItemsCount],
  );
  const subMenuItems = desktopSubMenu();

  const activeFirstLevelItemIndex = useMemo(
    () => firstLevelItems.findIndex((item) => item.active),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstLevelItems, currentBurgerSection],
  );

  const secondLevelItems: ProjectLinkType[] = useMemo(
    () =>
      safeGet(() => firstLevelItems[activeFirstLevelItemIndex].children, []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [firstLevelItems, currentBurgerSection, activeFirstLevelItemIndex],
  );

  const secondLevelVisibleItems = useMemo(
    () => secondLevelItems.slice(0, secondLevelItemsCount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBurgerSection, secondLevelItems, secondLevelItemsCount],
  );

  const secondLevelDropdownItems = useMemo(
    () => secondLevelItems.slice(secondLevelItemsCount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentBurgerSection, secondLevelItems, secondLevelItemsCount],
  );

  const activeSecondLevelItemIndex = useMemo(
    () => secondLevelItems.findIndex((item) => item.active),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [secondLevelItems, currentBurgerSection],
  );

  useEffect(() => {
    setIsFirstLevelMoreActive(
      activeFirstLevelItemIndex > firstLevelItemsCount - 1,
    );
  }, [currentBurgerSection, activeFirstLevelItemIndex, firstLevelItemsCount]);

  useEffect(() => {
    setIsSecondLevelMoreActive(
      activeSecondLevelItemIndex > secondLevelItemsCount - 1,
    );
  }, [currentBurgerSection, activeSecondLevelItemIndex, secondLevelItemsCount]);

  useOnClickOutside(
    firstLevelMoreContainerNode,
    () => setIsFirstLevelDropdownOpened(false),
    isFirstLevelDropdownOpened,
  );
  useOnClickOutside(
    secondLevelContainerNode,
    () => setIsSecondLevelDropdownOpened(false),
    isSecondLevelDropdownOpened,
  );

  const sendReachGoal = useCallback((top100) => {
    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: `${top100}_clicks`,
    });
  }, []);

  const onResize = useMemo(
    () =>
      // eslint-disable-next-line sonarjs/cognitive-complexity
      _throttle(() => {
        const width = window.innerWidth;

        if (width < 1102) {
          setFirstLevelItemsCount(8);
        } else if (width < 1171) {
          if (isBranding) {
            if (firstLevelItemsCount !== 8) {
              setFirstLevelItemsCount(8);
            }
          } else if (firstLevelItemsCount !== 9) {
            setFirstLevelItemsCount(9);
          }
        } else if (width < 1280) {
          if (isBranding) {
            if (firstLevelItemsCount !== 8) {
              setFirstLevelItemsCount(8);
            }
          } else if (firstLevelItemsCount !== 10) {
            setFirstLevelItemsCount(10);
          }
        } else {
          setFirstLevelItemsCount(ITEMS_COUNT_DEFAULT);
        }

        const subItemsLimit = SUBITEMS_COUNT[activeFirstLevelItemIndex];

        if (subItemsLimit) {
          const { code } = firstLevelItems[activeFirstLevelItemIndex];

          if (code === 'horoscope-description-main') {
            let count = zodiacSign
              ? subItemsLimit.fullOpened
              : subItemsLimit.full;

            if (width < 1270) {
              count = zodiacSign
                ? subItemsLimit.shortOpened
                : subItemsLimit.short;
            }

            if (width <= 1259) {
              count = zodiacSign
                ? subItemsLimit.shortOpened
                : subItemsLimit.short;
            } else if (width >= 1260 && width <= 1279 && zodiacSign) {
              count = subItemsLimit.fullOpened - 1;
            }

            setSecondLevelItemsCount(count);
          } else if (width > 1279) {
            setSecondLevelItemsCount(subItemsLimit.full);
          } else if (
            code === 'articles-list' ||
            code === 'fortune-main' ||
            code?.toString().includes('taro') ||
            code?.toString() ===
              '/names-main|names-name-.+|names-patronymic-.+/g'
          ) {
            setSecondLevelItemsCount(subItemsLimit.short);
          } else {
            setSecondLevelItemsCount(subItemsLimit.full);
          }
        } else {
          setSecondLevelItemsCount(SUBITEMS_COUNT_DEFAULT);
        }
      }, 100),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      firstLevelItems,
      firstLevelItemsCount,
      activeFirstLevelItemIndex,
      zodiacSign,
      currentBurgerSection,
      isBranding,
    ],
  );

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useEffect(() => {
    setIsFirstLevelDropdownOpened(false);
    setIsSecondLevelDropdownOpened(false);
    onResize();
  }, [currentBurgerSection, onResize]);

  const renderItem: any = ({
    item,
    index,
    top100,
    itemTitle = '',
    isDropdownMenu = false,
    isSecondLevelMenu = false,
  }: {
    item: ProjectLinkType;
    index: number;
    top100: string;
    itemTitle?: string;
    isDropdownMenu?: boolean;
    isSecondLevelMenu?: boolean;
  }) => {
    const { title, href, isExternal, active, ...others } = item;
    const key = `${href}-${title}-${index}`;

    // TODO(HORO-0): fix type
    const Tag: any = isExternal ? 'a' : Link;

    const target = isExternal ? { target: '_blank' } : {};

    const itemClassName = cn(
      s.link,
      active && s.linkActive,
      isDropdownMenu && s.linkDropdown,
      isSecondLevelMenu && s.linkSub,
    );

    const addItemTitleInTop100 = itemTitle ? `${itemTitle}_` : '';

    const top100Markup = getTop100Markup(
      false,
      top100Prefix,
      `${top100}::${addItemTitleInTop100}${title}::${active ? 'current' : 'not_current'}`,
    );

    return (
      <Fragment key={key}>
        <Tag
          className={itemClassName}
          to={{
            pathname: href,
            state: { loading: true },
          }}
          href={href}
          {...top100Markup}
          onClick={() => {
            sendReachGoal(top100);
            setIsFirstLevelDropdownOpened(false);
            setIsSecondLevelDropdownOpened(false);
          }}
          {...target}
          {...others}
        >
          {title}
        </Tag>
        {isSecondLevelMenu && item.children && item.children.length ? (
          <div className={s.linkChildren}>
            <Icon id="left-brace" className={s.braceIcon} />
            {item.children.map((child) =>
              renderItem({
                item: child,
                index: -1,
                top100,
                isSecondLevelMenu: true,
              }),
            )}
            <Icon id="right-brace" className={s.braceIcon} />
          </div>
        ) : null}
      </Fragment>
    );
  };

  return (
    <div
      className={cn(
        s.menuWrapper,
        isBranding && s.menuWrapperBranding,
        wrapperClassName,
      )}
      ref={scrollableNode}
    >
      <div
        className={cn(
          s.menu,
          isSticky && s.sticky,
          isVisible && s.visible,
          className,
          'menu-horizontal',
        )}
      >
        <div className={s.menuContainer} ref={firstLevelMoreContainerNode}>
          <div className={s.menuItems}>
            {firstLevelVisibleItems.map((item, index) =>
              renderItem({
                item,
                index,
                top100: 'top_menu',
              }),
            )}
            <div className={s.moreContainer}>
              <button
                type="button"
                className={cn(
                  s.link,
                  s.more,
                  (isFirstLevelMoreActive || isFirstLevelDropdownOpened) &&
                    s.moreActive,
                )}
                onClick={() => {
                  setIsFirstLevelDropdownOpened(!isFirstLevelDropdownOpened);
                  setIsSecondLevelDropdownOpened(false);
                  sendReachGoal('top_menu');
                }}
                {...getTop100Markup(
                  false,
                  top100Prefix,
                  `top_menu::more::${isFirstLevelDropdownOpened ? 'hide' : 'show'}`,
                )}
              >
                Ещё
              </button>
              <div
                className={cn(
                  s.dropdownMenu,
                  isFirstLevelDropdownOpened && s.dropdownMenuOpened,
                )}
              >
                {firstLevelDropdownItems.map((item, index) =>
                  renderItem({
                    item,
                    index: firstLevelVisibleItems.length + index,
                    top100: 'top_menu',
                    itemTitle: 'Ещё',
                    isDropdownMenu: true,
                  }),
                )}
                <div className={s.dropdownSubMenu}>
                  {subMenuItems.map((item) =>
                    renderItem({
                      item,
                      index: -1,
                      top100: 'top_menu',
                      itemTitle: 'Ещё',
                      isDropdownMenu: true,
                    }),
                  )}
                  <span className={cn(s.link, s.linkSub, s.ageLimit)}>18+</span>
                  <div className={s.legal}>
                    На информационном ресурсе применяются рекомендательные
                    технологии в соответствии с&nbsp;
                    <a
                      href="https://help.rambler.ru/legal/2183/uslovia"
                      target="_blank"
                      className={s.legalLink}
                      rel="noopener noreferrer nofollow"
                      {...getTop100Markup(
                        false,
                        top100Prefix,
                        'top_menu::Ещё_Правила::not_current',
                      )}
                    >
                      Правилами
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {withSubMenu && (
        <div className={cn(s.subMenu, subClassName)}>
          <div className={s.subMenuContainer} ref={secondLevelContainerNode}>
            {secondLevelVisibleItems && (
              <div className={s.subMenuItems}>
                {secondLevelVisibleItems.map((item) =>
                  renderItem({
                    item,
                    index: -1,
                    top100: 'bottom_menu',
                    isSecondLevelMenu: true,
                  }),
                )}
                {secondLevelDropdownItems.length ? (
                  <div className={s.moreContainer}>
                    <button
                      type="button"
                      className={cn(
                        s.link,
                        s.linkSub,
                        s.more,
                        (isSecondLevelMoreActive ||
                          isSecondLevelDropdownOpened) &&
                          s.moreActive,
                      )}
                      onClick={() => {
                        setIsSecondLevelDropdownOpened(
                          !isSecondLevelDropdownOpened,
                        );
                        setIsFirstLevelDropdownOpened(false);
                        sendReachGoal('bottom_menu');
                      }}
                      {...getTop100Markup(
                        false,
                        top100Prefix,
                        `bottom_menu::more::${isBottomMenuShown}`,
                      )}
                    >
                      Ещё
                    </button>
                    <div
                      className={cn(
                        s.subMenuDropdownMenu,
                        isSecondLevelDropdownOpened &&
                          s.subMenuDropdownMenuOpened,
                        secondLevelDropdownItems.length > 6 &&
                          s.subMenuDropdownMenuWide,
                      )}
                      style={{
                        height:
                          secondLevelDropdownItems.length > 6
                            ? `${DROPDOWN_ITEM_HEIGHT * Math.ceil(secondLevelDropdownItems.length / 2)}px`
                            : 'auto',
                      }}
                    >
                      {secondLevelDropdownItems.map((item) =>
                        renderItem({
                          item,
                          index: -1,
                          top100: 'bottom_menu',
                          itemTitle: 'Ещё',
                          isDropdownMenu: true,
                          isSecondLevelMenu: true,
                        }),
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Menu.defaultProps = {
  className: '',
  subClassName: '',
  wrapperClassName: '',
  withSubMenu: true,
};

export { Menu };
