import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import Button from '@rambler-components/button';

import { YANDEX_METRICS } from 'config/constants/counters';

import { YandexEvent } from 'utils/counters/YandexEvent';
import { sentryReactSend } from 'utils/sentry/client';

import { Icon } from 'common/components/Icon';
import { FeedbackForm } from 'common/components/FeedbackForm';
import { Typography } from 'common/components/Typography';

import { useNotificationsContext } from 'common/components/Notifications/context/notificationsContext';

import s from './index.css';

const selectRuntimeData = createSelector(
  [(state: IAppState) => state.runtime.splits.horo_pwa_install_split],
  (installSplit) => ({
    installSplit,
  }),
);

interface IPwaMobileNotification {
  variant: 'card' | 'float';
}

const DAYS_OF_DELAY = 14;
const PWA_DELAY = DAYS_OF_DELAY * 24 * 60 * 60 * 1000; // 14 дней *
const TIME_FOR_DECLINED_CLOSE = 200;

function PwaMobileNotification({ variant }: IPwaMobileNotification) {
  const { installSplit } = useSelector(selectRuntimeData);
  const { setIsPwaClosed } = useNotificationsContext();
  const [isVisible, setIsVisible] = useState(false);
  const [isFloatIconVisible, setIsFloatIconVisible] = useState(true);
  const [isFloatCardHidden, setIsFloatCardHidden] = useState(true);
  const [deviceType, setDeviceType] = useState<'iOS' | 'android' | undefined>(
    undefined,
  );
  // eslint-disable-next-line sonarjs/deprecation
  const deferredPrompt = useRef<BeforeInstallPromptEvent>();

  const sendToYandex = useCallback((text: string) => {
    new YandexEvent(YANDEX_METRICS.COMMON).send({
      type: 'reachGoal',
      data: text,
    });
  }, []);

  const showPwaNotification = () => {
    const lastShown =
      window.localStorage.getItem('rh_pwaSubLastShownTime') || '0';
    const now = Number(new Date());
    const newValue = now - PWA_DELAY >= Number(lastShown); // показываем не чаще чем раз в 2 недели

    if (isVisible !== newValue) {
      setIsVisible(newValue);
    }
  };

  useEffect(() => {
    const { userAgent } = window.navigator;
    const isChromium = !!window.chrome;
    // eslint-disable-next-line sonarjs/slow-regex
    const isIPhoneSafari = /iPhone.+Version\/[\d.]+.*Safari/i.test(userAgent);
    const isIPadSafari =
      /Macintosh/i.test(navigator.userAgent) &&
      Boolean(navigator.maxTouchPoints) &&
      navigator.maxTouchPoints > 1;
    const isAndroid = /android/i.test(userAgent);

    setDeviceType(isAndroid ? 'android' : 'iOS');

    if (isIPadSafari || isIPhoneSafari) {
      showPwaNotification();
    } else if (isChromium) {
      if (!deferredPrompt.current) {
        window.addEventListener(
          'beforeinstallprompt',
          // eslint-disable-next-line sonarjs/deprecation
          (e: BeforeInstallPromptEvent) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();

            if (isAndroid) {
              // Stash the event so it can be triggered later.
              deferredPrompt.current = e;

              showPwaNotification();
            }
          },
          { once: true },
        );
      }

      window.addEventListener(
        'appinstalled',
        () => {
          // Clear the deferredPrompt so it can be garbage collected
          deferredPrompt.current = undefined;
          // Optionally, send analytics event to indicate successful install

          sendToYandex('notification_system_instal');

          setIsVisible(false);
          setIsPwaClosed(true);
        },
        { once: true },
      );
    }
  }, [deferredPrompt.current]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClickSubmit = useCallback(async () => {
    sendToYandex('notification_pwa_custom_accepted');

    if (deferredPrompt.current) {
      // Show the prompt
      deferredPrompt.current.prompt();

      sendToYandex('notification_system_show');

      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.current.userChoice;
      // Optionally, send analytics event with outcome of user choice

      const ymText =
        outcome === 'accepted'
          ? 'notification_system_accepted'
          : 'notification_system_declined';

      sendToYandex(ymText);

      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt.current = undefined;
    }

    setIsVisible(false);
    setIsPwaClosed(true);
  }, [sendToYandex, setIsPwaClosed]);

  const onClose = useCallback(
    (type: 'closed' | 'declined') => {
      // Clear the deferredPrompt so it can be garbage collected
      deferredPrompt.current = undefined;
      // Optionally, send analytics event to indicate notification close

      try {
        window.localStorage.setItem(
          'rh_pwaSubLastShownTime',
          `${Number(new Date())}`,
        );
      } catch (error) {
        sentryReactSend(error);

        console.error(`${new Date()} ${error}`);
      }

      sendToYandex(`notification_pwa_custom_${type}`);

      setIsVisible(false);
      setIsPwaClosed(true);
    },
    [sendToYandex, setIsPwaClosed],
  );

  const footerActions = useMemo(
    () => (
      <div
        className={cn(
          s.cardFooter,
          deviceType === 'android' ? s.cardFooterAndroid : s.cardFooteriOS,
        )}
      >
        {deviceType === 'android' ? (
          <>
            <Button className={s.cardButton} onClick={onClickSubmit}>
              Добавить
            </Button>
            <Button
              type="white"
              className={s.cardButton}
              onClick={() => {
                setIsFloatCardHidden(true);
                setTimeout(() => onClose('declined'), TIME_FOR_DECLINED_CLOSE);
              }}
            >
              Не сейчас
            </Button>
          </>
        ) : (
          <>
            <Typography variant="defaultMedium" className={s.cardText}>
              Нажмите
              <Icon id="pwa-add" className={cn(s.icon, s.iconAdd)} />
            </Typography>
            <Typography variant="defaultMedium" className={s.cardText}>
              и выберите «На экран “Домой”»
            </Typography>
          </>
        )}
      </div>
    ),
    [deviceType, onClickSubmit, onClose],
  );

  const cardVariant = useMemo(
    () => (
      <div className={s.card}>
        {deviceType === 'iOS' && (
          <Icon
            id="close"
            className={cn(s.icon, s.iconClose)}
            onClick={() => onClose('closed')}
          />
        )}
        <Typography variant="h3" component="h2" className={s.cardTitle}>
          Быстрый доступ
        </Typography>
        <Typography variant="defaultMedium" className={s.cardText}>
          Теперь Рамблер/гороскопы можно добавить на главный экран
        </Typography>
        {footerActions}
      </div>
    ),
    [deviceType, footerActions, onClose],
  );

  const floatVariant = useMemo(
    () => (
      <>
        {isFloatIconVisible && (
          <Icon
            id="logo-star"
            className={cn(s.icon, s.iconStarCircle)}
            onClick={() => {
              sendToYandex('notification_pwa_popup_click');

              setIsFloatIconVisible(false);
              setIsFloatCardHidden(false);
            }}
          />
        )}
        <div
          className={cn(
            s.card,
            s.cardFloat,
            isFloatCardHidden && s.cardFloatHidden,
          )}
        >
          <div className={s.cardFloatDescription}>
            <div className={s.cardFloatIconWrapper}>
              <Icon id="logo-star" className={cn(s.icon, s.iconStarRounded)} />
            </div>
            <Typography variant="defaultMedium" className={s.cardText}>
              Добавьте Рамблер/гороскопы
              <br />
              прямо на домашний экран!
            </Typography>
          </div>
          {deviceType === 'iOS' && (
            <Icon
              id="pwa-close"
              className={cn(s.icon, s.iconClose, s.iconCloseFloat)}
              onClick={() => {
                setIsFloatCardHidden(true);
                setTimeout(() => onClose('declined'), TIME_FOR_DECLINED_CLOSE);
              }}
            />
          )}
          {footerActions}
        </div>
      </>
    ),
    [
      isFloatIconVisible,
      isFloatCardHidden,
      deviceType,
      footerActions,
      sendToYandex,
      onClose,
    ],
  );

  const fallback = useMemo(
    () => (variant === 'float' ? <FeedbackForm /> : null),
    [variant],
  );

  if (!isVisible) return fallback;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (variant === 'card' && installSplit === 73) {
    sendToYandex('notification_pwa_custom_show');

    return cardVariant;
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  if (variant === 'float' && installSplit === 72) {
    sendToYandex('notification_pwa_popup_show');

    return floatVariant;
  }

  return fallback;
}

PwaMobileNotification.defaultProps = {
  className: '',
};

export default PwaMobileNotification;
