import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import Cookies from 'js-cookie';

import RamblerTopline, { UserProfileType } from '@rambler-components/topline';
import Popup from '@rambler-components/popup';
import Geo from '@rambler-components/geo';

import '@rambler-components/topline/styles.css';

import { SET_RUNTIME_VARIABLE } from 'common/redux/runtime';
import { YANDEX_METRICS } from 'config/constants/counters';

import { YandexEvent } from 'utils/counters/YandexEvent';
import { top100Wrapper } from 'utils/counters/top100Wrapper';

import { Ad } from 'common/components/Ad';
import s from './index.css';

interface ToplinePropsType {
  top100Prefix: string;
}

export const ToplineDesktop: React.FC<ToplinePropsType> = React.memo(() => {
  const dispatch = useDispatch();

  // TODO(HORO-0): пока релизим без виджетов
  // const headerData = useSelector((state: IAppState) => state.header, shallowEqual);

  const [userProfile, setUserProfile] = useState<UserProfileType | null>(null);
  const [geoOpened, setGeoOpened] = useState(false);

  const onCloseGeo = useCallback(() => setGeoOpened(false), []);
  const onOpenGeo = useCallback(() => setGeoOpened(true), []);

  const onUserId = useCallback(
    (userId?: string) => {
      dispatch({
        type: SET_RUNTIME_VARIABLE,
        name: 'userId',
        value: userId,
      });
      top100Wrapper.sendUserId(userId);
    },
    [dispatch],
  );

  const onUserInfo = useCallback(
    (userInfo: UserProfileType) => {
      if (!userProfile) {
        dispatch({
          type: SET_RUNTIME_VARIABLE,
          name: 'userEmail',
          value: userInfo?.info.email || '',
        });

        setUserProfile(userInfo);
      }
    },
    [dispatch, userProfile],
  );

  const onUserState = useCallback((state: 'login' | 'logout') => {
    new YandexEvent(YANDEX_METRICS.COMMON).send(
      {
        type: 'reachGoal',
        data: `user_${state}`,
        params: {
          auth_type: state === 'login' ? 'ID_authorized' : 'ID_unauthorized',
        },
      },
      () => {
        Cookies.remove('haccount');
        window.location.reload();
      },
    );
  }, []);

  const onSticky = useCallback(
    (sticky: boolean) => {
      dispatch({
        type: SET_RUNTIME_VARIABLE,
        name: 'isStickyTopline',
        value: sticky,
      });
    },
    [dispatch],
  );

  const promoAd = <Ad name="topline_banner" />;

  return (
    <div className={cn(s.root, 'ad_branding_header')}>
      <RamblerTopline
        projectCode="horoscopes"
        withWeather={false}
        withExchangeRates={false}
        onUserId={onUserId}
        onUserInfo={onUserInfo}
        onUserState={onUserState}
        promoAd={promoAd}
        onSticky={onSticky}
        onOpenGeo={onOpenGeo}
      />

      <Popup width={560} zIndex={5005} isOpen={geoOpened} onClose={onCloseGeo}>
        <Geo onClose={onCloseGeo} />
      </Popup>
    </div>
  );
});

ToplineDesktop.displayName = 'ToplineDesktop';
