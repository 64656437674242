/**
 * Функция, возращающая объект с параметрами для share buttons
 *
 * @param url - url карточки
 * @param title - заголовок карточки
 * @param styles - объект стилей для кнопок
 * @param image - картинка карточки
 * @param description - описание карточки
 * @param extraButtons - дополнительный список кнопок
 * @param setCounters - флаг установки счетчиков
 * @param iconSize - флаг установки счетчиков
 */
const getShareInitParams = ({
  title,
  url,
  image,
  description,
  styles,
  extraButtons,
  setCounters = false,
  isMobile = false,
  buttonHeight = 40,
  iconSize = 17,
}: {
  title: string;
  url?: string;
  image?: string;
  description?: string;
  styles?: Record<string, string | number>;
  extraButtons?: string[];
  setCounters?: boolean;
  isMobile: boolean;
  buttonHeight?: number;
  iconSize?: number;
}) => {
  const customData = {
    ...(url ? { url } : {}),
    ...(title ? { title } : {}),
    ...(image ? { image } : {}),
    ...(description ? { description } : {}),
  };

  const buttons = isMobile
    ? ['odnoklassniki', 'viber', 'whatsapp', 'email', 'copy']
    : ['vkontakte', 'odnoklassniki', 'telegram', 'whatsapp', 'email', 'copy'];

  return {
    style: styles || {
      buttonHeight,
      iconSize,
      borderRadius: 50,
    },
    utm: 'utm_medium=social',
    counters: setCounters,
    buttons: [...buttons, ...(extraButtons || [])],

    vkontakte: {
      utm: 'utm_source=vksharing&utm_medium=social',
    },
    odnoklassniki: {
      utm: 'utm_source=oksharing&utm_medium=social',
    },
    telegram: {
      utm: 'utm_source=tgsharing&utm_medium=social',
    },
    whatsapp: {
      utm: 'utm_source=washaring&utm_medium=social',
    },
    viber: {
      utm: 'utm_source=vbsharing&utm_medium=social',
    },
    email: {
      utm: 'utm_source=emailsharing&utm_medium=social',
    },
    copy: {
      utm: 'utm_source=linksharing&utm_medium=social',
    },
    ...customData,
  };
};

export { getShareInitParams };
