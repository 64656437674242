import { safeGet } from 'utils/safeGet';

/**
 * Очистка теста от артефактов
 * @param str - строка для очистки
 */
export const getPureText = (str: string): string =>
  safeGet(
    // eslint-disable-next-line sonarjs/slow-regex
    () => str.replace(/<\/?[^>]+>/g, ''),
    '',
  );

/**
 * @see BROWSER_ONLY
 *
 * Принимает html с мнемониками типа &quot; и возвращает обычный текст
 *
 * @param html - html с мнемониками
 */
export const decodeHTMLEntities = (html: string): string => {
  const textArea = document.createElement('textarea');

  textArea.innerHTML = html;

  return textArea.value;
};
