import { getShareInitParams } from './getShareInitParams';

interface PropsType {
  node: HTMLElement;
  cardUrl?: string;
  cardTitle: string;
  isMobile: boolean;
  buttonHeight?: number;
  iconSize?: number;
  cardImage?: string;
  cardDescription?: string;
  styles?: Record<string, string | number>;
  extraButtons?: string[];
  setCounters?: boolean;
}

/**
 * Функция, инициализирующая кнопки шаринга
 *
 * @param node - node узел кнопок
 * @param cardUrl - url карточки
 * @param cardTitle - заголовок карточки
 * @param styles - объект стилей для кнопок
 * @param image - картинка карточки
 * @param description - описание карточки
 * @param extraButtons - дополнительный список кнопок
 * @param setCounters - флаг установки счетчиков
 * @param isMobile - флаг мобильной версии
 * @param buttonHeight - размер иконок
 * @param iconSize - размер иконок
 */
const initShareButtons = ({
  node,
  cardUrl,
  cardTitle,
  cardImage,
  cardDescription,
  styles,
  extraButtons,
  setCounters,
  isMobile,
  buttonHeight,
  iconSize,
}: PropsType) => {
  const initScript = () => {
    const params = getShareInitParams({
      title: cardTitle,
      url: cardUrl,
      image: cardImage,
      description: cardDescription,
      styles,
      extraButtons,
      setCounters,
      isMobile,
      buttonHeight,
      iconSize,
    });

    // @ts-expect-error: RamblerShare does not exist
    window.RamblerShare.init(node, params);
  };

  // @ts-expect-error: RamblerShare does not exist
  if (window.RamblerShare) {
    // @ts-expect-error: RamblerShare does not exist
    window.RamblerShare.destroy(node);
    initScript();
  } else {
    const script = document.createElement('script');

    script.onload = initScript;
    script.async = true;
    script.src = 'https://developers.rambler.ru/likes/v1/widget.js';
    document.head.appendChild(script);
  }
};

export { initShareButtons };
