import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import loadable from '@loadable/component';
import { Article } from 'common/components/schemaOrg/Article';
import { Top100Context } from 'common/contexts/top100Context';

import { selectPageData } from 'utils/selectPageData';

import Layout from 'desktop/components/Layout';

import s from './index.css';

const Card = loadable(() => import('common/components/Card'));

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isPremium,
    (state: IAppState) => state.runtime.currentPage,
    (state: IAppState) => state.runtime.currentSection,
    (state: IAppState) => state.runtime.top100,
  ],
  (
    isPremium,
    currentPage,
    currentSection,
    top100,
    // eslint-disable-next-line max-params
  ) => ({
    isPremium,
    currentPage,
    currentSection,
    top100,
  }),
);

function LongreadDetailed() {
  const { isPremium, currentPage, currentSection, top100 } =
    useSelector(selectData);

  const data = useSelector((state: IAppState) =>
    selectPageData(state, currentPage, currentSection),
  );

  const mainCardData = isPremium && data ? data.content : null;
  const top100PrefixEnding = mainCardData ? 'content_page' : 'list_page';
  const top100Prefix = isPremium ? `${top100}_${top100PrefixEnding}` : '';
  const memoTop100Prefix = useMemo(
    () => ({ top100Prefix: `${top100Prefix}::content_card::page` }),
    [top100Prefix],
  );

  if (isPremium && mainCardData) {
    return (
      // eslint-disable-next-line sonarjs/no-clear-text-protocols
      <div itemScope itemType="http://schema.org/Article">
        <Article />
        <Top100Context.Provider value={memoTop100Prefix}>
          <Card
            {...mainCardData}
            isMainCard
            isLazy={false}
            isNeedLongreadTopicList={false}
            className={s.card}
            isPremium
          />
        </Top100Context.Provider>
      </div>
    );
  }

  return <Layout withSchema withSubscribePopup />;
}

export default LongreadDetailed;
