import React from 'react';

import { fetchPageData } from 'common/redux/pages';
import { fetchVkVideoWidgetData } from 'common/redux/vkVideoWidget';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const homeRoutes: IAppRoute[] = [
  {
    name: 'home-general',
    path: '/',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema isHomePage />,
    fetchData: async ({ dispatch, getState }) => {
      await dispatch(
        fetchPageData('horoscope', 'main', undefined, false, {
          top100: 'zodiac_general_main',
          isBubblesMoved: true,
        }),
      );

      const vkWidgetEnable =
        getState().runtime.adminData.settings.vk_video_promo;

      // делаем запрос только когда виджет включен в админке
      if (vkWidgetEnable) {
        await dispatch(fetchVkVideoWidgetData());
      }
    },
  },
];
