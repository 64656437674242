import { months, hairDates } from 'config/constants/routes';
import { IAppRoute } from 'typings/AppRoute';

import { fetchPageData } from 'common/redux/pages';

import HairMainPage from 'desktop/pages/Hair/Main';
import HairDatePage from 'desktop/pages/Hair/Date';
import HairPeriodPage from 'desktop/pages/Hair/Period';

export const hairRoutes: IAppRoute[] = [
  {
    name: 'hair-main',
    path: '/hair',
    exact: true,
    component: HairMainPage,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('hair', 'main', undefined, false, {
          top100: 'hair_main',
          isBubblesMoved: true,
        }),
      );
    },
  },
  {
    name: 'hair-date',
    path: `/hair/:period(${months})/:date(${hairDates})`,
    exact: true,
    component: HairDatePage,
    fetchData: async ({ dispatch }, { params }) => {
      const { period, date } = params;

      await dispatch(
        fetchPageData(
          'hair',
          'date',
          {
            url: `${period}/${date}`,
            hairPeriod: period,
          },
          false,
          {
            top100: 'hair_period_date',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
  {
    name: 'hair-period',
    path: `/hair/:period(${months})`,
    exact: true,
    component: HairPeriodPage,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'hair',
          'period',
          {
            url: period,
            hairPeriod: period,
          },
          false,
          {
            top100: 'hair_period_month',
            isBubblesMoved: true,
          },
        ),
      );
    },
  },
];
