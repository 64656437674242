import React from 'react';

import { dreamsPeriodsExt, routeLetters } from 'config/constants/routes';

import { fetchPageData } from 'common/redux/pages';

import { IAppRoute } from 'typings/AppRoute';

import DefaultPage from 'desktop/pages/Default';

export const dreamsRoutes: IAppRoute[] = [
  // Dreams
  {
    name: 'dreams',
    path: '/dreams',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('dreams', 'main', undefined, false, {
          top100: 'dreams_interpretation_main_words',
        }),
      );
    },
  },
  // Dreams word
  {
    name: 'dreams-word',
    path: '/dreams/word/:word',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { word } = params;

      await dispatch(
        fetchPageData(
          'dreams',
          'word',
          {
            url: word,
            dreamsWord: word,
          },
          false,
          {
            top100: 'dreams_interpretation_word_page',
          },
        ),
      );
    },
  },
  {
    name: 'dreams-letters',
    path: '/dreams/sonnik-ot-a-do-ya',
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }) => {
      await dispatch(
        fetchPageData('dreams', 'letters', undefined, false, {
          top100: 'dreams_interpretation_letters_page',
        }),
      );
    },
  },
  {
    name: 'dreams-letter',
    path: `/dreams/letter/:letter(${routeLetters})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { letter } = params;

      await dispatch(
        fetchPageData(
          'dreams',
          'letters',
          {
            url: letter,
            dreamsLetter: letter,
          },
          false,
          {
            top100: 'dreams_interpretation_letter_page',
          },
        ),
      );
    },
  },
  {
    name: 'dreams-periods',
    path: `/dreams/:period(${dreamsPeriodsExt})`,
    exact: true,
    render: (props) => <DefaultPage {...props} withSchema />,
    fetchData: async ({ dispatch }, { params }) => {
      const { period } = params;

      await dispatch(
        fetchPageData(
          'dreams',
          'periods',
          {
            url: period,
            dreamsPeriod: period,
          },
          false,
          {
            top100: 'dreams_interpretation_period_page',
          },
        ),
      );
    },
  },
];
