import { createSelector } from 'reselect';

export const selectPageData = createSelector(
  (
    state: IAppState,
    currentPage: RuntimeType['currentPage'],
    currentSection: RuntimeType['currentSection'],
  ) => state.pages?.[currentPage]?.[currentSection],
  (data) => data,
);
