import isEmpty from 'lodash.isempty';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

const DATA_LIMIT = 10;

const urlPart = {
  dreams: 'dreams/word',
  names: 'names/name',
  omens: 'primety/word',
  taro: '',
};

export class HDError extends Error {
  type?: 'unavailable' | 'empty';
}

export const fetchSuggestedData = async (
  state: any,
  query: string,
  type: keyof APIsUrls['search'],
) => {
  try {
    const url = `${API.search[type]}?query=${query}&limit=10`;
    const data = await fetch({
      path: url,
      state,
      apiVersion: 'v3',
    });

    const response: any = await Promise.resolve(data.slice(0, DATA_LIMIT));

    if (isEmpty(response)) {
      const err = new Error(
        `${new Date()} Не удалось получить данные саджеста: ${url}`,
      );
      throw err;
    }

    const suggests = response.map((item: any) => ({
      type: '',
      ind: item.id,
      url: `/${urlPart[type]}/${item.slug}/`,
      descr: item.name,
      sug: item.name,
    }));

    return {
      query,
      suggests,
    };
  } catch (error) {
    sentryReactSend(error);
    console.error(`${new Date()} ${error}`);

    return {
      query,
      suggests: [],
    };
  }
};

export const fetchHumanDesignCitiesData = async (
  state: any,
  query: string,
  country: string,
) => {
  try {
    const url = `${API.humandesign.places}autocomplete?language=ru&input=${encodeURIComponent(query)}&country=${country}`;
    const data = await fetch({
      path: url,
      state,
      apiVersion: 'geo',
    });

    if (!data || !data.search) {
      const err = new HDError(
        `${new Date()} Данные саджеста городов ДЧ недоступны: ${url}`,
      );

      err.type = 'unavailable';
      throw err;
    }

    const response: any = await Promise.resolve(data.cities);

    if (isEmpty(response)) {
      const err = new HDError(
        `${new Date()} Данные саджеста городов ДЧ пуст: ${url}`,
      );

      err.type = 'empty';
      throw err;
    }

    const suggests = response.map((item: any) => {
      const descr = item.description.replace(
        /[`~!@#$%^&*_|+=?;:'".<>{}[\]\\/]/gi,
        '',
      );

      return {
        type: '',
        ind: item.place_id,
        url: '',
        descr,
        sug: descr,
        timezone: item.timezone.replace(/[`~!@#$%^&*|=?;:'".<>{}[\]\\]/gi, ''),
      };
    });

    return {
      query,
      suggests,
    };
  } catch (error) {
    sentryReactSend(error);
    console.error(`${new Date()} ${error}`);

    throw error;
  }
};
