import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useFetchNextPage } from 'common/hooks/useFetchNextPage';

import s from './index.css';

const HEIGHT_BEFORE_FETCH_NEXT_CARDS = 300;

const selectData = createSelector(
  [
    (state: IAppState) => state.runtime.isPremium,
    (state: IAppState) => state.runtime.hasNextPage,
    (state: IAppState) => state.runtime.currentParams.isFullScreen,
  ],
  (isPremium, hasNextPage, isFullScreen) => ({
    isPremium,
    hasNextPage,
    isFullScreen,
  }),
);

function Footer() {
  const fetchPointRef = useRef(null);
  const { isPremium, hasNextPage, isFullScreen } = useSelector(selectData);

  useFetchNextPage(fetchPointRef, HEIGHT_BEFORE_FETCH_NEXT_CARDS);

  return (
    <>
      {!isPremium && !isFullScreen && <div ref={fetchPointRef} />}
      {!hasNextPage && !isFullScreen && <div className={s.emptyFooter} />}
    </>
  );
}

export { Footer };
