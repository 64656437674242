/**
 * Безопасное получение свойства объекта, возвращает fallback при ошибке
 *
 * @param getPropFn - Функция возращающая свойство объекта
 * @param fallback - Вернется если произошла ошибка при получении свойства
 */

// eslint-disable-next-line sonarjs/function-return-type
export const safeGet = <T, V = undefined>(getPropFn: () => T, fallback?: V) => {
  try {
    const result = getPropFn();

    // если не делать as V
    // TS считает что функция возвращает T | V | undefined
    // Но undefined она возвращает только тогда, когда fallback не передается,
    // но в таком случае и V = undefined
    // Так что safeGet всегда возвращает T | V
    return result || (fallback as V);
  } catch {
    return fallback as V;
  }
};
